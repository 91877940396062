import {excelExport} from 'common/tablesv8/excelExport'
import {saveAsCSV} from 'common/tablesv8/csvExport'
import {format} from 'date-fns'
import {CellContext, ColumnDef} from '@tanstack/react-table'
import styled from 'styled-components/macro'

type GetExportFileBlobProps = {
  columns: ColumnDef<unknown>[]
  data: unknown[][]
  fileType: 'csv' | 'xlsx' | 'pdf'
  fileName: string
}

export const compareIgnoreCase = (a: string, b: string) => {
  var _a = a && typeof a === 'string' ? a?.toLowerCase() : a || ''
  var _b = b && typeof b === 'string' ? b?.toLowerCase() : b || ''
  if (_a < _b) return -1
  if (_a > _b) return 1
  return 0
}

export function getExportFileBlob({
  columns,
  data,
  fileType,
  fileName,
}: GetExportFileBlobProps) {
  if (fileType === 'csv') {
    saveAsCSV(columns, data, fileName)
  } else if (fileType === 'xlsx') {
    excelExport({columns, data, fileName})
    return false
  }
  return false
}

// export const compareIgnoreCase = (a: string, b: string) => {
//   let _a = a && typeof a === 'string' ? a?.toLowerCase() : a || ''
//   let _b = b && typeof b === 'string' ? b?.toLowerCase() : b || ''
//   if (_a < _b) return -1
//   if (_a > _b) return 1
//   return 0
// }


export const formatDate = ({value}: {value: Date | string | number}) => {
  try {
    const newDate = new Date(value)
    if (isNaN(newDate.getTime())) {
      return null
    } else {
      return format(
        new Date(value),
        'MM/dd/yyyy',
      )}
  } catch (error) {
    return null
  }
}

export const formatDateTime = ({value}: {value: Date | string | number}) => {
  const timeFormat = 'h:mm a'
  return value
    ? format(
        new Date(value),
         'M/d/yyyy h:mm a',
      )
    : null
}

const RightAlign = styled.div`
  width: 100%;
  text-align: right;
`

const numberFormatter = (valueToConvert: number, digits: number) => {
  return new Intl.NumberFormat(undefined, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(valueToConvert)
}

export const numberColumn = (instance: CellContext<any, unknown>) => {
  const value = instance.getValue()
  return <RightAlign>{numberFormatter(Number(value || 0), 0)}</RightAlign>
}



export const currencyColumn = (instance: CellContext<any, unknown>) => {
  const value = instance.getValue()
  return <RightAlign>$&nbsp;{numberFormatter(Number(value || 0), 2)}</RightAlign>
}
