import * as React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: transparent;
  flex-direction: row;
  max-width: 400px;
  min-width: 300px;
`

const InputAndFilter = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
`

const SearchBarContainer = styled.div`
  display: flex;
  width: 100%;
`

const SearchBar = styled.input`
  flex-grow: 1;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  outline: none;
  ::placeholder {
    color: var(#888);
    opacity: 1;
    font-weight: 400;
  }

  :hover {
    border: 1px solid rgba(34, 36, 38, 0.35); /*matches semantic ui dropdown hover*/
  }
  :focus {
    outline: none;
    border: 1px solid var(--tertiary) !important;
    border-right-color: var(--tertiary) !important;
    box-shadow: 0px 0px 6px 1px #ccc !important;
  }
`

const ClearButton = styled.button`
  color: var(--primary);
  font-weight: 700;
  font-size: 13px;
  padding: 0 10px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: 2px;
  :hover {
    text-decoration: underline;
  }
  :focus {
    outline: 1px solid #666;
    text-decoration: underline;
  }
  :after {
    content: 'Clear';
  }
`

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

type Props = {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchValue: string
  clearFunction: () => void
  assetFilter?: { label: string; value: string }
  className?: string
  placeholder?: boolean
  dataCy?: string
  searchType?: string
  onClick?: () => void
}

const SearchContainer = ({
  handleSearch,
  searchValue,
  clearFunction,
  className = '',
  placeholder = true,
  dataCy = '',
  searchType = '',
  onClick = () => undefined,
}: Props) => {
  const [capTab, setCapTab] = React.useState('records')
  React.useEffect(() => {
    setCapTab(capitalizeFirstLetter(searchType || 'records'))
  }, [searchType])

  return (
    <Container className={className}>
      <SearchBarContainer>
        <InputAndFilter data-testid="search-inputfield">
          <SearchBar
            data-cy={dataCy}
            placeholder={placeholder ? `Search ${capTab}...` : ''}
            onChange={handleSearch}
            value={searchValue || ''}
            autoComplete="off"
            onClick={onClick}
          ></SearchBar>
        </InputAndFilter>
        {searchValue?.length ? (
          <ClearButton
            data-cy={'clear-button'}
            onClick={clearFunction}
            data-testid="search-clear"
          />
        ) : null}
      </SearchBarContainer>
    </Container>
  )
}
export default SearchContainer
