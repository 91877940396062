//
import React, {Fragment} from 'react'
import styled from 'styled-components/macro'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'

import CourseInformation from './CourseInformation'
import EventInformationSection from './EventInformationSection'
import LandingPageNav from './LandingPageNav'
import Sponsors from './Sponsors'
import Header from './Header'
import Spinner from 'assets/Spinner'
import {AdminBanner, AdminBannerPush} from 'assets/elements'
import LandingPageButtons from './LandingPageButtons'
import AgendaImgSrc from 'assets/images/AgendaUBCF2023.png'

const Container = styled.div`
  margin: 0 5px;
  width: 99vw;
  max-width: 1200px;
  h1 {
    font-size: 2rem;
  }
  padding-top: 62px;
  @media (min-width: 1000px) {
    h1 {
      font-size: 2.5rem;
    }
    width: 90%;
    margin: 0 auto;
    padding-top: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`

const HomePageContent = styled.div`
  margin: 3px 10px;
  width: 100%;
  padding: 0 5px;
`

const LowerAdminBanner = styled(AdminBanner)`
  top: 65px;
  @media screen and (min-width: 1000px) {
    top: 0;
  }
`

const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
`

const AgendaImg = styled.img`
  height: auto;
  width: 100%;
`

const Panel = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 0;
  /* padding: 1em 1em; */
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LeftPanel = styled(Panel)`
  width: 100%;
  @media (min-width: 1000px) {
    width: 75%;
  }
`

const RightPanel = styled(Panel)`
  width: 100%;
  @media (min-width: 1000px) {
    width: 25%;
  }
`

const LandingPage = props => {
  const location = useLocation()
  const pathname = location.pathname
  const registrationReceipt = pathname.includes('/event-receipt/')
  const adminGoBack = () => {
    let lastPage = localStorage.getItem('lastPage')
    if (lastPage && lastPage !== '') {
      props.history.push(
        `/${props.event ? props.event.slug : ''}/admin/${lastPage}`,
      )
    } else {
      props.history.push(`/${props.event ? props.event.slug : ''}/admin`)
    }
  }
  React.useEffect(() => {
    // console.log(registrationReceipt)
    if (registrationReceipt) {
      Swal.fire(
        'Registration Complete',
        'Your registration was submitted successfully. You will receive an email with your registration details.',
        'success',
      )
    }
  }, [registrationReceipt])
  return (
    <Container>
      {props.event === undefined && props.status !== 'loading' ? (
        <Spinner />
      ) : (
        <Fragment>
          <LandingPageNav
            event={props.event}
            textColor={props.textColor}
            showSponsorshipButton={props.showSponsorshipButton}
          />
          {props.adminMode ? (
            <Fragment>
              <LowerAdminBanner onClick={adminGoBack} data-cy="adminBanner">
                You are in Planner Mode, click here to return
              </LowerAdminBanner>
              <AdminBannerPush />
            </Fragment>
          ) : (
            <Fragment />
          )}
          {props.event.homeHeaderHTML &&
          props.event.showHeaderOnLandingPage !== false ? (
            <Header homeHeaderHTML={props.event.homeHeaderHTML || ''} />
          ) : (
            <Fragment />
          )}
          <Fragment />
          <BodySection>
            <LeftPanel>
              {props.event && props.event.homepageHTML ? (
                <HomePageContent
                  dangerouslySetInnerHTML={{
                    __html: props.event.homepageHTML,
                  }}
                />
              ) : (
                <span />
              )}
            </LeftPanel>
            <RightPanel className="rightPanel">
              {props.sponsorImagesDisplayed.length > 0 ? (
                <Sponsors
                  sponsorImagesDisplayed={props.sponsorImagesDisplayed || []}
                  buttonColor={props.event.buttonColor || ''}
                  textColor={props.textColor}
                />
              ) : (
                <Fragment />
              )}
              <LandingPageButtons
                event={props.event}
                textColor={props.textColor}
                showSponsorshipButton={props.showSponsorshipButton}
              />
              <EventInformationSection
                event={props.event}
                textColor={props.textColor}
              />
              {props.event.courseAddress ? (
                <iframe
                  title="EventMap"
                  width="300"
                  height="350"
                  frameBorder="0"
                  src={`https://www.google.com/maps/embed/v1/place?key=${String(
                    process.env.REACT_APP_GOOGLE_API_KEY,
                  )}&q=${String(
                    props.event && props.event.courseAddress,
                  )}&zoom=13`}
                  allowFullScreen
                />
              ) : (
                <div />
              )}
              <CourseInformation
                name={props.event.courseName ? props.event.courseName : ''}
                description={
                  props.event.courseDescription
                    ? props.event.courseDescription
                    : ''
                }
                website={
                  props.event.courseWebsite ? props.event.courseWebsite : ''
                }
              />
              {(props.event.slug === 'ubcf' ||
                props.event.slug === 'ubcftest') && (
                <AgendaImg src={AgendaImgSrc} />
              )}
            </RightPanel>
          </BodySection>
        </Fragment>
      )}
    </Container>
  )
}

export default LandingPage
