// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from "styled-components"

type ColorsType = {[key: string]: string}
export const colors: ColorsType = {
  primary: '#0a284a',
  secondary: '#007dc3',
  darkolive: '#3D491F',
  moss: '#778737',
  lime: '#B9CF33',
  stone: '#E4DED2',
  subduedmoss: '#70785E',
  brown: '#5F3B0C',
  maple: '#E2BF7E',
  keylime: '#DCE799',
  successgreen: '#4DC591',
  selectedgreen: '#F0F6E1',
  gold: '#FEC143',
  vermilion: '#D24341',
  tan: '#D3BF90',
  lavender: '#FBECEC',
  black: '#222222',
  sonicsilver: '#767676',
  concrete: '#CCCCCC',
  graphite: '#949494',
  coolgray: '#D2D2D7',
  cultured: '#F5F5F7',
  platinum: '#EAEAEC',
  dimgray: '#696969',
  flamingo: '#FF6467',
  coral: '#FF7A50',
  rust: '#DC8534',
  seafoam: '#2BA9B5',
  steelblue: '#80B4C5',
  denim: '#4DACDF',
  jet: '#333333',
  lombard: '#C381EB',
  orange: '#FF9802',
  amber: '#FFC008',
  yellow: '#FFEA3B',
  green: '#4CAF50',
  blue: '#2196F3',
  indigo: '#3F51B5',
  buttonblue: '#007bff',
  red: '#F44335',
  white: '#FFFFFF',
  wheat: '#FFE4AE',
  grey050: '#FAFAFA',
  grey100: '#F5F5F5',
  grey200: '#EEEEEE',
  grey300: '#E0E0E0',
  grey400: '#BDBDBD',
  grey500: '#9E9E9E',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#424242',
  grey900: '#212121',
}

const GlobalStyle = createGlobalStyle`
    body {
        --grey050: ${colors.grey050};
        --grey100: ${colors.grey100};
        --grey200: ${colors.grey200};
        --grey300: ${colors.grey300};
        --grey400: ${colors.grey400};
        --grey500: ${colors.grey500};
        --grey600: ${colors.grey600};
        --grey700: ${colors.grey700};
        --grey800: ${colors.grey800};
        --grey900: ${colors.grey900};
        --primary: ${colors.primary};
        --secondary: ${colors.secondary};
        --darkolive: ${colors.darkolive};
        --moss: ${colors.moss};
        --lime: ${colors.lime};
        --stone: ${colors.stone};
        --subduedmoss: ${colors.subduedmoss};
        --brown: ${colors.brown};
        --maple: ${colors.maple};
        --keylime: ${colors.keylime};
        --successgreen: ${colors.successgreen};
        --selectedgreen: ${colors.selectedgreen};
        --gold: ${colors.gold};
        --vermilion: ${colors.vermilion};
        --tan: ${colors.tan};
        --lavender: ${colors.lavender};
        --black: ${colors.black};
        --sonicsilver: ${colors.sonicsilver};
        --concrete: ${colors.concrete};
        --graphite: ${colors.graphite};
        --coolgray: ${colors.coolgray};
        --cultured: ${colors.cultured};
        --platinum: ${colors.platinum};
        --dimgray: ${colors.dimgray};
        --flamingo: ${colors.flamingo};
        --coral: ${colors.coral};
        --rust: ${colors.rust};
        --seafoam: ${colors.seafoam};
        --steelblue: ${colors.steelblue};
        --denim: ${colors.denim};
        --jet: ${colors.jet};
        --lombard: ${colors.lombard};
        --orange: ${colors.orange};
        --amber: ${colors.amber};
        --yellow: ${colors.yellow};
        --green: ${colors.green};
        --blue: ${colors.blue};
        --indigo: ${colors.indigo};
        --buttonblue: ${colors.buttonblue};
        --red: ${colors.red};
        --white: ${colors.white};
        --wheat: ${colors.wheat};
    }`

export default GlobalStyle